const checkAddressStepSettings = {
  country: { label: "Страна" },
  region: { label: "Область" },
  city: { label: "Город" },
  apartment: { label: "Адрес" },
  apartmentNumber: { label: "Квартира" },
  zipCode: { label: "Индекс" },
}

export const metroBlockSettings = {
  name: { label: "Название станции" },
  walkDistance: { label: "Расстояние пешком" },
  carDistance: { label: "Расстояние на машине" },
  carTime: { label: "Время на машине" },
}

export default checkAddressStepSettings

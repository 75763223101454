import React from "react"
import { useForm } from "react-hook-form"
import {
  BasicApartmentInfoStepFields,
  BasicApartmentInfoStepSchema,
  getBasicApartmentInfoFields,
} from "kui-crm_actions"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import checkAddressStepSettings from "./content"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import InspectionMetroBlock from "../../../common/InspectionMetroBlock"

const CheckAddressStep = () => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<BasicApartmentInfoStepFields>({
    defaultValues: {
      ...apartmentInfo,
      ...formStore.fields,
      apartment: {
        address: formStore.fields?.apartment?.address || apartmentInfo?.address,
        location:
          formStore.fields?.apartment?.location || apartmentInfo?.geolocation,
      },
    },
    // @ts-ignore
    resolver: yupResolver(BasicApartmentInfoStepSchema),
  })
  const fields = getBasicApartmentInfoFields(
    undefined,
    checkAddressStepSettings,
  )

  const handleSubmit = (data: BasicApartmentInfoStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      title="Проверьте адрес"
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <Grid container spacing={2}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={12} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}

        <InspectionMetroBlock form={form} />
      </Grid>
    </FormWrapper>
  )
}

export default observer(CheckAddressStep)

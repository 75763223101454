import { getRoomsImagesSteps as getRoomsImagesStepsKUI } from "kui-crm_actions"
import { InspectionRoomPhotoTypes, InspectionTypes } from "kui-crm"
import { InspectionRoomsFields } from "../types"

type RoomImageStepParams = {
  label: string
  title: string
  type: InspectionRoomPhotoTypes
}

const getRoomsImagesSteps = (
  type: InspectionTypes,
  roomsParams?: InspectionRoomsFields | null,
): RoomImageStepParams[] =>
  getRoomsImagesStepsKUI(
    type,
    {
      kitchen: {
        label: "Загрузите фотографии кухни",
        title: "Кухня",
      },
      room: (index: number) => ({
        label: `Загрузите фотографии ${index}комнаты`,
        title: `Комната ${index + 1}`,
      }),
      bedroom: (index: number) => ({
        label: `Загрузите фотографии ${index}спальни`,
        title: `Спальня ${index + 1}`,
      }),
      bathroom: (index: number) => ({
        label: `Загрузите фотографии ${index}ванной комнаты`,
        title: `Ванная комната ${index + 1}`,
      }),
      toilet: (index: number) => ({
        label: `Загрузите фотографии ${index}туалета`,
        title: `Туалет ${index + 1}`,
      }),
      combinedBathroom: (index: number) => ({
        label: `Загрузите фотографии ${index}совместного санузла`,
        title: `Совместный санузел ${index + 1}`,
      }),
      others: {
        label:
          "Загрузите фотографии коридора, холла, балкона и других помещений",
        title: "Коридор, холл, балкон и др.",
      },
      keys: {
        label: "Загрузите фотографии ключей",
        title: "Ключи",
      },
      entrance: {
        label: "Загрузите фотографии входа и прилегающей территории",
        title: "Входная группа",
      },
      parking: {
        label: "Загрузите фотографии паркинга",
        title: "Паркинг",
      },
      additional: {
        label: "Внесите дополнительную информацию",
        title: "Дополнительная информация",
      },
    },
    roomsParams,
  )

export default getRoomsImagesSteps

import { InspectionTypes } from "kui-crm"

export const getInspectionTypes = (
  hasRentalContract?: boolean,
  hasServiceContract?: boolean,
): InspectionTypes[] => {
  if (!hasServiceContract) {
    return ["initial", "inventory"]
  }
  if (hasServiceContract && !hasRentalContract) return ["transfer"]
  if (hasServiceContract && hasRentalContract) return ["transfer", "regular"]
  return []
}

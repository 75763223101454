import React from "react"
import { MetroBlockForModal } from "kui-crm"
import { useWatch } from "react-hook-form"
import { observer } from "mobx-react"
import useStore from "../../../../hooks/useStore"
import { metroBlockSettings } from "./content"
import { InspectionMetroBlockProps } from "./types"

const InspectionMetroBlock = (props: InspectionMetroBlockProps) => {
  const { form, disabled } = props

  const { mapStore, metroStore } = useStore()
  const location = useWatch({
    control: form.control,
    name: "apartment.location",
  })

  return (
    <MetroBlockForModal
      label="Метро"
      form={form}
      isEditing={!disabled}
      mapStore={mapStore}
      metroStore={metroStore}
      fieldsSettings={metroBlockSettings}
      coords={location}
    />
  )
}

export default observer(InspectionMetroBlock)

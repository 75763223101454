import { useEffect, useState } from "react"
import { InputParams, MeterTypes } from "kui-crm"
import { UseFormReturn } from "react-hook-form"
import { MeterFormFieldsProps } from "./types"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import getFullMeterFields from "./getFullMeterFields"

export type MeterInfoFieldsProps = {
  form: UseFormReturn<any>
} & MeterFormFieldsProps

export type MeterInfoFields = InputParams<any>[]

const useMeterInfoFields = (props: MeterInfoFieldsProps): MeterInfoFields => {
  const { resource, index, form } = props
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const defaultMetersValues = formStore.fields?.meters?.[index]
  const defaultType = resource === "water" ? "cold" : "T1"
  const withMeterType = resource === "water" || resource === "electricity"
  const defaultMeterType = withMeterType ? defaultType : null

  const [meterType, setMeterType] = useState<MeterTypes>(
    defaultMetersValues?.type || defaultMeterType,
  )

  const meterFields = getFullMeterFields({
    withMeterType,
    setMeterType,
    resource,
    meterType,
  })

  useEffect(() => {
    form.setValue(`meters.${index}.resource`, resource)
  }, [])

  return meterFields
}

export default useMeterInfoFields

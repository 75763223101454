import React from "react"
import { MeterInfoStepProps } from "kui-crm_actions"
import { MeterTypes } from "kui-crm"
import InspectionImagesStep from "../InspectionImagesStep"
import meterLabels, { meterTypes } from "../PartialMeterInfoStep/content"
import useApartmentStore from "../../../../hooks/useApartmentStore"

const MeterImagesStep = (props: MeterInfoStepProps) => {
  const { index, resource } = props
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const defaultMetersValues = formStore.fields?.meters?.[index]
  const type = defaultMetersValues?.type as MeterTypes
  const label = `Загрузите фотографии счетчика ${
    type ? `${meterTypes[type]} ` : ""
  }${meterLabels[resource]}`

  return (
    <InspectionImagesStep
      withComment={false}
      label={label}
      entityName={`meters.${index}`}
      name="images"
    />
  )
}

export default MeterImagesStep

import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import { observer } from "mobx-react"
import { Container } from "kui-basic"
import { LoaderState, PageBreadcrumbs } from "kui-crm"
import styled from "@emotion/styled"
import useInspectionStore from "./store"
import InspectionForm from "./components/InspectionForm"
import Title from "../../components/common/Title"
import inspectionTypeLabels from "../ApartmentPage/common/InspectionFirstScreen/InspectionTypeButton/content"
import Page from "../../components/common/Page"
import InspectionFeedbackBlock from "./components/InspectionFeedbackBlock"
import { DynamicRoutes } from "../../routes"

const InspectionPage = () => {
  const { id, inspection_id } = useParams()
  const inspectionStore = useInspectionStore()
  const { fetchInspection, resetInspection, type, date } = inspectionStore
  const { actionLoader, loader } = inspectionStore
  const inspectionsPath = DynamicRoutes.inspections(Number(id))
  const navigate = useNavigate()

  const initInspectionPage = async () => {
    if (inspection_id && id) fetchInspection(Number(id), Number(inspection_id))
  }

  const handlePrevClick = () => {
    navigate(inspectionsPath)
  }

  useEffect(() => {
    initInspectionPage()
    return () => resetInspection()
  }, [])

  if (!type) return null

  const title = `${inspectionTypeLabels[type]} от ${date?.toFormat(
    "dd.MM.yyyy",
  )}`

  const breadcrumbs = [
    { link: inspectionsPath, title: "Inspections" },
    { link: null, title },
  ]

  return (
    <Page loader={loader}>
      <Container>
        <StyledBreadcrumbs onPrev={handlePrevClick} breadcrumbs={breadcrumbs} />
        <Title title={title} />
        <InspectionFeedbackBlock />
        <InspectionForm />
      </Container>

      <LoaderState loader={actionLoader} />
    </Page>
  )
}

export default observer(InspectionPage)

const StyledBreadcrumbs = styled(PageBreadcrumbs)`
  padding-top: 16px;
`

import * as yup from "yup"
import { defaultRequiredMessage, MeterTypes, ResourceTypes } from "kui-crm"
import { DateTime } from "luxon"
import { NumberSchema } from "yup"

const getValueSchema = (rule: NumberSchema) =>
  rule
    .min(0)
    .required(defaultRequiredMessage)
    .transform((value) => (Number.isNaN(value) ? undefined : value))

const FullMeterInfoFieldsSchema = {
  type: yup.string(),
  resource: yup.string(),
  initialValueT1: yup.number().when("resource", {
    is: (resource: ResourceTypes) => resource === "electricity",
    then: getValueSchema,
  }),
  initialValueT2: yup.number().when(["resource", "type"], {
    is: (resource: ResourceTypes, type: MeterTypes) =>
      resource === "electricity" && type !== "T1",
    then: getValueSchema,
  }),
  initialValueT3: yup.number().when(["resource", "type"], {
    is: (resource: ResourceTypes, type: MeterTypes) =>
      resource === "electricity" && type === "T3",
    then: getValueSchema,
  }),
  initialValue: yup.number().when("resource", {
    is: (resource: ResourceTypes) => resource !== "electricity",
    then: getValueSchema,
  }),
  startDate: yup
    .mixed()
    .when("startDate", {
      is: (value: DateTime) => value,
      then: (rule) =>
        rule.test(
          "check-start-date",
          "Select a date in the past",
          (value) => Number((value as DateTime)?.diffNow("days").days) <= 0,
        ),
    })
    .nullable(),
  noTariff: yup.boolean(),
}

const FullMeterInfoStepSchema = yup
  .object()
  .shape(FullMeterInfoFieldsSchema, [["startDate", "startDate"]])

export default FullMeterInfoStepSchema
export { FullMeterInfoFieldsSchema }

import { MapStore, MetroStore } from "kui-crm"
import AuthStore from "./AuthStore"
import ApartmentsPageStore from "./ApartmentsPageStore"
import CabinetStore from "./CabinetStore"

class RootStore {
  authStore: AuthStore

  apartmentsPageStore: ApartmentsPageStore

  cabinetStore: CabinetStore

  mapStore: MapStore

  metroStore: MetroStore

  constructor() {
    this.authStore = new AuthStore()
    this.cabinetStore = new CabinetStore()
    this.apartmentsPageStore = new ApartmentsPageStore()
    this.mapStore = new MapStore()
    this.metroStore = new MetroStore()
  }
}

export default RootStore

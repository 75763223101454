import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import Card from "../../../../../components/common/Card"
import Title from "../../../../../components/common/Title"
import { getInspectionTypes } from "./content"
import InspectionTypeButton from "../InspectionTypeButton"
import { InspectionTypesBlockProps } from "./types"
import useApartmentStore from "../../../../../hooks/useApartmentStore"

const InspectionTypesBlock = (props: InspectionTypesBlockProps) => {
  const { apartmentInfo } = useApartmentStore()
  const fields = getInspectionTypes(
    apartmentInfo?.hasRentalContract,
    apartmentInfo?.hasServiceContract,
  )

  return (
    <StyledWrapper>
      <Title title="Выберите действие" />
      <Card>
        {fields.map((type) => (
          <InspectionTypeButton key={type} type={type} {...props} />
        ))}
      </Card>
    </StyledWrapper>
  )
}

export default observer(InspectionTypesBlock)

const StyledWrapper = styled.div`
  flex-grow: 1;
`

import {
  ElectricityValues,
  basicFieldsOfNewResourceMeter,
  MeterTypes,
  BasicMeterFieldsSettings,
  WaterValues,
} from "kui-crm"
import { addToArrayByCondition } from "kui-utils"
import fullMeterInfoStepSettings from "./content"

type MeterFieldsSettings = {
  withMeterType: boolean
  setMeterType: (type: MeterTypes) => void
} & BasicMeterFieldsSettings

const getFullMeterFields = ({
  withMeterType,
  setMeterType,
  resource,
  meterType,
}: MeterFieldsSettings) => [
  ...addToArrayByCondition(withMeterType, {
    label: "Тип",
    name: "type",
    options: resource === "water" ? WaterValues : ElectricityValues,
    handleChange: setMeterType,
    variant: "select",
  }),
  ...basicFieldsOfNewResourceMeter(
    { resource, meterType },
    fullMeterInfoStepSettings,
  ),
]

export default getFullMeterFields

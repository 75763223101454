import React from "react"
import { Button, Grid } from "kui-basic"
import { NextArrowIcon, PrevArrowIcon } from "kui-icon"
import styled from "@emotion/styled"
import { FormFooterButtonStylesProps, FormFooterProps } from "./types"

const FormFooter = (props: FormFooterProps) => {
  const { label, onPrev, actionButton, disabled } = props

  return (
    <StyledFooter alignItems="center" container spacing={2}>
      <Grid item>
        <Button isCircle size="s" variant="whiteWithGray" onClick={onPrev}>
          <PrevArrowIcon />
        </Button>
      </Grid>
      {actionButton && (
        <StyledActionButtonWrapper item>
          {actionButton}
        </StyledActionButtonWrapper>
      )}
      <StyledButtonWrapper item fullWidth={!actionButton}>
        <Button
          disabled={disabled}
          fullWidth={!actionButton}
          isCircle={!!actionButton}
          endIcon={actionButton ? undefined : <NextArrowIcon />}
          size="s"
          type="submit"
        >
          {actionButton ? <NextArrowIcon /> : label}
        </Button>
      </StyledButtonWrapper>
    </StyledFooter>
  )
}

FormFooter.defaultProps = {
  label: "Следующий этап",
}

export default FormFooter

const StyledFooter = styled(Grid)`
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: 24px;
  background: ${({ theme }) => theme.palette.background.light2};
  z-index: 1;
`

const StyledActionButtonWrapper = styled(Grid)`
  flex-grow: 1;
`

const StyledButtonWrapper = styled(Grid)<FormFooterButtonStylesProps>`
  flex-grow: ${({ fullWidth }) => (fullWidth ? 1 : "initial")};
`

const fullMeterInfoStepSettings = {
  withAutoSending: {
    label: "Авто чтение",
    hintMessage:
      "Данные счетчика автоматически передаются в управляющую компанию",
  },
  noTariff: {
    label: "Без тарифа",
    hintMessage:
      "Показания напрямую не влияют на стоимость, сумма расход берется из квитанции",
  },
  initialValue: { label: "Перовначальные показания" },
  initialValueT1: { label: "Перовначальные показания T1" },
  initialValueT2: { label: "Перовначальные показания T2" },
  initialValueT3: { label: "Перовначальные показания T3" },
  number: { label: "Номер" },
  startDate: { label: "Дата активации" },
  passportFile: { label: "Паспорт" },
}

export default fullMeterInfoStepSettings

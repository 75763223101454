import React from "react"
import { Button, Container } from "kui-basic"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { PrevArrowIcon } from "kui-icon"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import Title from "../../../../../components/common/Title"
import Card from "../../../../../components/common/Card"
import OptionButton from "../../../../../components/common/OptionButton"
import { getTransferTypes } from "./content"

const TransferTypeStep = () => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const fields = getTransferTypes(
    apartmentInfo?.hasRentalContract,
    apartmentInfo?.hasServiceContract,
  )

  const handleClick = (transferType: any) => {
    formStore.updateFormFields({ transferType })
    formStore.nextStep()
  }

  return (
    <StyledContainer>
      <StyledContent>
        <Title title="Выберите тип акта приема-передачи" />
        <Card>
          {fields.map((option) => (
            <OptionButton
              label={option.label}
              value={option.value}
              onClick={handleClick}
            />
          ))}
        </Card>
      </StyledContent>
      <StyledFooter>
        <Button
          fullWidth
          size="s"
          variant="whiteWithGray"
          onClick={formStore.prevStep}
          startIcon={<PrevArrowIcon />}
        >
          Предыдущий этап
        </Button>
      </StyledFooter>
    </StyledContainer>
  )
}

export default observer(TransferTypeStep)

const StyledFooter = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: 24px;
  background: ${({ theme }) => theme.palette.background.light2};
`

const StyledContainer = styled(Container)`
  position: relative;
  min-height: calc(100vh - 49px);
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  flex-grow: 1;
`

export const getTransferTypes = (
  hasRentalContract?: boolean,
  hasServiceContract?: boolean,
) => {
  if (hasServiceContract && !hasRentalContract)
    return [
      { label: "От Maroom к жильцу", value: "maroom_tenant" },
      { label: "От Maroom к собственнику", value: "maroom_landlord" },
    ]
  if (hasServiceContract && hasRentalContract)
    return [
      { label: "От жильца к Maroom", value: "tenant_maroom" },
      { label: "От жильца к собственнику", value: "tenant_landlord" },
    ]
  return []
}

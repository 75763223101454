export const roomLabelsByType = {
  kitchen: "Кухня",
  bathroom: "Ванная",
  room: "Комната",
  bedroom: "Спальня",
  entrance: "Входная группа",
  others: "Коридор, холл, балкон и др.",
  toilet: "Туалет",
  combined_bathroom: "Совместный санузел",
  keys: "Ключи",
  additional: "Дополнительная информация",
  parking: "Паркинг",
}
